<template>
    <div class="mainWrapper pb-0">
        <section class="blockElement space homeBanner bg-white">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-lg-6 text-center">
                        <h1 class="bold mb-md-4">{{$t('socialTrading.socialText1')}}</h1>
                        <p class="mb-md-4">{{$t('socialTrading.socialText2')}}</p>
                        <div class="dualButton text-center my-5">
                            <a href="javascript:void(0)" class="button fillBtn zulu_btn me-3">{{$t('socialTrading.socialText3')}}</a>
                            <a href="javascript:void(0)" class="button  zulu_btn border-button rounded">{{$t('socialTrading.socialText4')}}</a>
                        </div>
                    </div>
                    <div class="zuluGuard my-md-5 text-center">
                        <img src="/assets/images/social-trading-hero-banner.png" alt="social trading banner" title="social trading banner" />
                    </div>
                    <div class="row align-items-center">
                        <div class="col-md-6 px-5">
                            <h2 class="f-20">{{$t('socialTrading.socialText5')}} </h2>
                        </div>
                        <div class="col-md-6">
                            <p>{{$t('socialTrading.socialText6')}}</p>
                        </div>
                    </div>
                </div>
                </div>
        </section>
        <section class="blockElement space bg-white border-top border-bottom border-2">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="mb-md-4 pb-4 text-center">{{$t('socialTrading.socialText7')}}</h2>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 mb-4">
                        <div class="card-body">
                            <div class="d-block mb-4"><v-lazy-image class="d-block h-70" src="/assets/images/learn.svg" :alt="$t('socialTrading.socialText8')" /></div>
                            <div>
                                <h4 class="bold">{{$t('socialTrading.socialText8')}}</h4>
                                <p>{{$t('socialTrading.socialText9')}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 mb-4">
                        <div class="card-body">
                            <div class="d-block mb-4"><v-lazy-image class="d-block h-70" src="/assets/images/community.svg" :alt="$t('socialTrading.socialText10')" /></div>
                            <div>
                                <h4 class="bold">{{$t('socialTrading.socialText10')}} </h4>
                                <p>{{$t('socialTrading.socialText11')}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 mb-4">
                        <div class="card-body">
                            <div class="d-block mb-4"><v-lazy-image class="d-block h-70" src="/assets/images/risk-monitoring.svg" :alt="$t('socialTrading.socialText12')" /></div>
                            <div>
                                <h4 class="bold">{{$t('socialTrading.socialText12')}}</h4>
                                <p>{{$t('socialTrading.socialText13')}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-lg-6 mb-4">
                        <div class="card-body">
                            <div class="d-block mb-4"><v-lazy-image class="d-block h-70" src="/assets/images/diversion.svg" :alt="$t('socialTrading.socialText14')" /></div>
                            <div>
                                <h4 class="bold">{{$t('socialTrading.socialText14')}}</h4>
                                <p>{{$t('socialTrading.socialText15')}}</p>
                            </div>

                        </div>
                    </div>
                    <div class="dualButton text-center my-5">
                        <a href="javascript:void(0)" class="button fillBtn zulu_btn me-3">{{$t('socialTrading.socialText3')}}</a>
                        <a href="javascript:void(0)" class="button  zulu_btn border-button rounded">{{$t('socialTrading.socialText4')}}</a>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement space ">
            <div class="container">
                <div class="row">
                    <div class="col-12 mb-3 mb-md-4 text-center">
                        <h2>{{$t('socialTrading.socialText16')}}</h2>
                    </div>
                    <table class="wl-table">
                        <thead>
                            <tr>
                                <th>{{$t('socialTrading.socialText17')}}</th>
                                <th>{{$t('socialTrading.socialText18')}}</th>
                                <th>{{$t('socialTrading.socialText19')}}</th>
                            </tr>
                        </thead>
                            <tr>
                                <td>{{$t('socialTrading.socialText20')}} </td>
                                <td class="text-center">
                                    <v-lazy-image src="/assets/images/x.svg" :alt="$t('socialTrading.socialText21')" />
                                </td>
                                <td class="text-center">
                                    <v-lazy-image src="/assets/images/v.svg" :alt="$t('socialTrading.socialText22')" />
                                </td>
                            </tr>
                        <tr>
                            <td>{{$t('socialTrading.socialText66')}}
                            </td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/x.svg" :alt="$t('socialTrading.socialText21')" />
                            </td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/v.svg" :alt="$t('socialTrading.socialText22')" />
                            </td>
                        </tr>
                        <tr>
                            <td>{{$t('socialTrading.socialText67')}} </td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/x.svg" :alt="$t('socialTrading.socialText21')" />
                            </td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/v.svg" :alt="$t('socialTrading.socialText22')" />
                            </td>
                        </tr>
                        <tr>
                            <td>{{$t('socialTrading.socialText68')}}</td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/v.svg" :alt="$t('socialTrading.socialText22')" />
                            </td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/v.svg" :alt="$t('socialTrading.socialText22')" />
                            </td>
                        </tr>
                        <tr>
                            <td>{{$t('socialTrading.socialText69')}} </td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/v.svg" :alt="$t('socialTrading.socialText22')" />
                            </td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/v.svg" :alt="$t('socialTrading.socialText22')" />
                            </td>
                        </tr>
                        <tr>
                            <td>{{$t('socialTrading.socialText70')}}</td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/x.svg" :alt="$t('socialTrading.socialText21')" />
                            </td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/v.svg" :alt="$t('socialTrading.socialText22')" />
                            </td>
                        </tr>
                        <tr>
                            <td>{{$t('socialTrading.socialText71')}} </td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/x.svg" :alt="$t('socialTrading.socialText21')" />
                            </td>
                            <td class="text-center">
                                <v-lazy-image src="/assets/images/v.svg" :alt="$t('socialTrading.socialText22')" />
                            </td>

                        </tr>
                    </table>
                </div>
            </div>
        </section>


        <section class="blockElement space automated4 bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="text-center mb-md-4 pb-4">{{$t('socialTrading.socialText23')}}</h2>
                        <ul class="listCounter">
                            <li class="d-flex align-items-start pb-4">
                                <span class="customXpad d-flex justify-content-center">
                                    <v-lazy-image class="d-block" src="/assets/images/open-account.svg" :alt="$t('socialTrading.socialText24')" :title="$t('socialTrading.socialText25')" />
                                </span>
                                <label class="d-block">
                                    <h4 class="mb-2 bold">{{$t('socialTrading.socialText26')}}</h4>
                                    <p>{{$t('socialTrading.socialText27')}}</p>
                                </label>
                            </li>
                            <li class="d-flex align-items-start pb-4">
                                <span class="customXpad d-flex justify-content-center">
                                    <v-lazy-image class="d-block" src="/assets/images/step-2.svg" :alt="$t('socialTrading.socialText28')" :title="$t('socialTrading.socialText29')" />
                                </span>
                                <label class="d-block">
                                    <h4 class="mb-2 bold">{{$t('socialTrading.socialText30')}}</h4>
                                    <p>{{$t('socialTrading.socialText31')}}</p>
                                </label>
                            </li>
                            <li class="d-flex align-items-start pb-4">
                                <span class="customXpad d-flex justify-content-center">
                                    <v-lazy-image class="d-block" src="/assets/images/Select-trader.svg" :alt="$t('socialTrading.socialText32')" :title="$t('socialTrading.socialText33')" />
                                </span>
                                <label class="d-block">
                                    <h4 class="mb-2 bold">{{$t('socialTrading.socialText34')}}</h4>
                                    <p>{{$t('socialTrading.socialText35')}}</p>
                                </label>
                            </li>
                            <li class="d-flex align-items-start pb-4">
                                <span class="customXpad d-flex justify-content-center">
                                    <v-lazy-image class="d-block" src="/assets/images/Start-journey.svg" :alt="$t('socialTrading.socialText36')" :title="$t('socialTrading.socialText37')" />
                                </span>
                                <label class="d-block">
                                    <h4 class="mb-2 bold">{{$t('socialTrading.socialText38')}}</h4>
                                    <p>{{$t('socialTrading.socialText39')}}</p>
                                </label>
                            </li>
                        </ul>

                    </div>
                    <div class="col-12">
                        <div class="dualButton d-flex align-items-center justify-content-center mt-4">
                            <a href="javascript:void(0)" class="button fillBtn zulu_btn large me-3 px-md-4">{{$t('socialTrading.socialText3')}}</a>
                            <a href="javascript:void(0)" class="button bg-white zulu_btn rounded border-button">{{$t('socialTrading.socialText4')}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="blockElement  space automated5">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="text-center mb-md-5 pb-4">{{$t('socialTrading.socialText40')}}</h2>
                    </div>
                    <div class="col-12 col-md-6 mb-4">
                        <div class="d-flex align-items-start">
                            <span class="automated me-3 flex-none"><v-lazy-image class="d-block" src="/assets/images/Simulation.svg" :alt="$t('socialTrading.socialText41')" :title="$t('socialTrading.socialText41')" /></span>
                            <div class="flex-none contentCalc">
                                <h4 class="bold">{{$t('socialTrading.socialText41')}}</h4>  
                                <p>{{$t('socialTrading.socialText42')}}</p>
                            </div> 
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-4">
                        <div class="d-flex align-items-start">
                            <span class="automated me-3 flex-none"><v-lazy-image class="d-block" src="/assets/images/Automator-1.svg" :alt="$t('socialTrading.socialText43')" :title="$t('socialTrading.socialText43')" /></span>
                            <div class="flex-none contentCalc">
                                <h4 class="bold">{{$t('socialTrading.socialText43')}}</h4>  
                                <p>{{$t('socialTrading.socialText44')}} <a href="automated-trading.html" target="_blank">{{$t('socialTrading.socialText45')}}</a>.{{$t('socialTrading.socialText46')}} </p>
                            </div> 
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-4">
                        <div class="d-flex align-items-start">
                            <span class="automated me-3 flex-none"><v-lazy-image class="d-block" src="/assets/images/ZuluGuard-1.svg" :alt="$t('socialTrading.socialText47')" :title="$t('socialTrading.socialText47')" /></span>
                            <div class="flex-none contentCalc">
                                <h4 class="bold">{{$t('socialTrading.socialText47')}}</h4>  
                                <p>{{$t('socialTrading.socialText48')}}</p>
                            </div> 
                        </div>
                    </div>
                    <div class="col-12 col-md-6 mb-4">
                        <div class="d-flex align-items-start">
                            <span class="automated me-3 flex-none"><v-lazy-image class="d-block" src="/assets/images/Trading-chart.svg" :alt="$t('socialTrading.socialText49')" :title="$t('socialTrading.socialText49')" /></span>
                            <div class="flex-none contentCalc">
                                <h4 class="bold">{{$t('socialTrading.socialText49')}}</h4>  
                                <p>{{$t('socialTrading.socialText50')}}</p>
                            </div> 
                        </div>
                    </div>
                    
                    <div class="col-12">
                        <div class="dualButton d-flex align-items-center justify-content-center mt-4">
                            <a href="javascript:void(0)" class="button fillBtn zulu_btn large me-3 px-md-4">{{$t('socialTrading.socialText3')}}</a>
                            <a href="javascript:void(0)" class="button bg-white zulu_btn rounded border-button">{{$t('socialTrading.socialText4')}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section class="blockElement space faqs automated6 bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2 class="mb-md-4 pb-4">{{$t('socialTrading.socialText51')}}</h2>
                    </div>
                    <div class="col-12">
                        <div class="accordion accordion-flush" id="accordionFlushExample">
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingOne">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    {{$t('socialTrading.socialText52')}}
                                  </button>
                                </h2>
                                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                  <div class="accordion-body">{{$t('socialTrading.socialText53')}}</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingTwo">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    {{$t('socialTrading.socialText54')}}
                                  </button>
                                </h2>
                                <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                  <div class="accordion-body">{{$t('socialTrading.socialText55')}}</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingThree">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    {{$t('socialTrading.socialText56')}}
                                  </button>
                                </h2>
                                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                  <div class="accordion-body">{{$t('socialTrading.socialText57')}}</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingFour">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                    {{$t('socialTrading.socialText58')}}
                                  </button>
                                </h2>
                                <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                  <div class="accordion-body">{{$t('socialTrading.socialText59')}}</div>
                                </div>
                            </div>
                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingfive">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapsefive">
                                    {{$t('socialTrading.socialText60')}}
                                  </button>
                                </h2>
                                <div id="flush-collapsefive" class="accordion-collapse collapse" aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                                  <div class="accordion-body">{{$t('socialTrading.socialText61')}}</div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingsix">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapsesix">
                                     {{$t('socialTrading.socialText62')}}
                                  </button>
                                </h2>
                                <div id="flush-collapsesix" class="accordion-collapse collapse" aria-labelledby="flush-headingsix" data-bs-parent="#accordionFlushExample">
                                  <div class="accordion-body">{{$t('socialTrading.socialText63')}}</div>
                                </div>
                            </div>

                            <div class="accordion-item">
                                <h2 class="accordion-header" id="flush-headingseven">
                                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseseven" aria-expanded="false" aria-controls="flush-collapseseven">
                                    {{$t('socialTrading.socialText64')}}
                                  </button>
                                </h2>
                                <div id="flush-collapseseven" class="accordion-collapse collapse" aria-labelledby="flush-headingseven" data-bs-parent="#accordionFlushExample">
                                  <div class="accordion-body">{{$t('socialTrading.socialText65')}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>